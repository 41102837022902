import ApiClient from './ApiClient';

class KnowledgebasesAPI extends ApiClient {
  constructor() {
    super('knowledgebases', { accountScoped: true });
  }

  searchDocuments(id, query) {
    return axios.post(`${this.url}/${id}/search`, { query });
  }

  addFile(id, file, files) {
    const timestamp = new Date().toString();

    const formData = new FormData();

    files.forEach(signed_id => {
      formData.append('knowledgebase[files][]', signed_id);
    });

    if (typeof file === 'string') {
      formData.append('knowledgebase[files][]', file);
    } else {
      formData.append('knowledgebase[files][]', file, file.name);
    }

    formData.append('knowledgebase[timestamp]', timestamp);

    return axios.patch(`${this.url}/${id}`, formData);
  }

  removeFiles(id, filesToKeep) {
    if (filesToKeep.length === 0) {
      return axios.patch(`${this.url}/${id}/clear_files`);
    }

    const timestamp = new Date().toString();

    const formData = new FormData();

    filesToKeep.forEach(signed_id => {
      formData.append('knowledgebase[files][]', signed_id);
    });

    formData.append('knowledgebase[timestamp]', timestamp);

    return axios.patch(`${this.url}/${id}`, formData);
  }

  updateSchema(id, schema) {
    return axios.post(`${this.url}/${id}/schema`, { schema: schema });
  }

  getSchema(id) {
    return axios.get(`${this.url}/${id}/schema`);
  }

}

export default new KnowledgebasesAPI();
