/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../../helper/URLHelper';

const AssistantWizard = () => import('./wizard/components/AssistantWizard.vue');
const SettingsContent = () => import('../Wrapper.vue');
const InboxHome = () => import('./Index.vue');
const Settings = () => import('./Settings.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/assistants'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_assistant_list';
        return {
          headerTitle: 'ASSISTANT_MGMT.HEADER',
          headerButtonText: 'SETTINGS.ASSISTANTS.NEW_ASSISTANT',
          icon: 'bot',
          newButtonRoutes: ['settings_assistant_new'],
          showBackButton,
        };
      },
      children: [
        {
          path: 'new',
          name: 'settings_assistant_new',
          component: AssistantWizard,
          roles: ['administrator'],
        },
        {
          path: '',
          name: 'settings_assistant',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_assistant_list',
          component: InboxHome,
          roles: ['administrator'],
        },
        {
          path: ':inboxId',
          name: 'settings_assistant_show',
          component: Settings,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
