import Vue from 'vue';
import types from '../mutation-types';
import { validate } from '../utils/validators/assistantWizard';
import accountTraits from '../data/traits.json';
import { randomName } from '../data/nameHelper';

const defaultName = randomName();

export default {
  namespaced: true,
  state: {
    step: 0,
    totalSteps: 6,
    name: defaultName,
    task: 'generic',
    model: 'gpt-4o', // deprecated
    humanEscalation: null,
    humanEscalationConfig: {},
    language: 'en_us', // deprecated
    avatar: false,
    channel: 'web_widget',
    activeTraits: [],
    channelConfig: {},
    error: false,
    tasks: [
      { key: 'generic', name: 'Generic assistant' },
      { key: 'product_manager', name: 'Product manager' },
      { key: 'marketplace_assistant', name: 'Marketplace assistant' },
      { key: 'review_manager', name: 'Review manager' },
      { key: 'lead_assistant', name: 'Lead generation assistant' },
      // { key: "medical_assistant", name: "Medical assistant" },
      { key: 'faq_assistant', name: 'FAQ assistant' },
      { key: 'intake_assistant', name: 'Intake assistant' },
    ],
    models: [
      { key: 'gpt-4o', name: 'GPT-4o' },
      { key: 'gpt-4', name: 'GPT-4' },
      { key: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo' },
    ],
    humanEscalationOptions: [
      {
        key: 'assign_team',
        title: 'Pass to team',
        description: 'Take over via the Rookoo help desk',
        configurationKey: 'team_id',
        icon: 'checkmark',
      },
      {
        key: 'close_conversation',
        title: 'Close conversation',
        description: 'Offer alternative ways to get help',
        configurationKey: 'instructions',
        icon: 'checkmark',
      },
    ],
    languages: [
      { key: 'en_us', name: 'English (US)' },
      { key: 'nl', name: 'Nederlands' },
    ],
    accountTraits: accountTraits,
    channels: [
      { key: 'web_widget', name: 'Website' },
      { key: 'facebook_page', name: 'Facebook Page' },
      { key: 'email', name: 'Email' },
      { key: 'api', name: 'API' },
      { key: 'facebook', name: 'Messenger', disabled: true },
      { key: 'whatsapp', name: 'WhatsApp', disabled: true },
      { key: 'sms', name: 'SMS', disabled: true },
      { key: 'slack', name: 'Slack', disabled: true },
      { key: 'microsoft_teams', name: 'Microsoft Teams', disabled: true },
      // { key: 'telegram', name: 'Telegram' },
      // { key: 'line', name: 'Line' },
    ],
  },
  getters: {
    currentStep: $state => $state.step,
    getName: $state => $state.name,
    getTask: $state => $state.task,
    getModel: $state => $state.model,
    getHumanEscalation: $state => $state.humanEscalation,
    getHumanEscalationConfig: $state => $state.humanEscalationConfig,
    getLanguage: $state => $state.language,
    getActiveTraits: $state => $state.activeTraits,
    getAvatar: $state => $state.avatar,
    getChannel: $state => $state.channel,
    getLanguages: $state => $state.languages,
    getAccountTraits: $state => $state.accountTraits,
    getTasks: $state => $state.tasks,
    getModels: $state => $state.models,
    getHumanEscalationOptions: $state => $state.humanEscalationOptions,
    getChannelConfig: $state => $state.channelConfig,
    getError: $state => $state.error,
    getChannels: $state => $state.channels,
  },
  mutations: {
    [types.ASSISTANTWIZARD_NEXT_STEP](state) {
      state.step += 1;
      state.error = false;
    },
    [types.ASSISTANTWIZARD_PREVIOUS_STEP](state) {
      state.step -= 1;
    },
    [types.ASSISTANTWIZARD_SET_NAME](state, name) {
      state.name = name;
    },
    [types.ASSISTANTWIZARD_SET_TASK](state, task) {
      state.task = task;
    },
    [types.ASSISTANTWIZARD_SET_HUMAN_ESCALATION](state, humanEscalation) {
      state.humanEscalation = humanEscalation;
    },
    [types.ASSISTANTWIZARD_SET_HUMAN_ESCALATION_CONFIG](
      state,
      humanEscalationConfig
    ) {
      state.humanEscalationConfig = humanEscalationConfig;
    },
    [types.ASSISTANTWIZARD_SET_LANGUAGE](state, language) {
      state.language = language;
    },
    [types.ASSISTANTWIZARD_SET_AVATAR](state, avatar) {
      state.avatar = avatar;
    },
    [types.ASSISTANTWIZARD_ADD_ACTIVE_TRAIT](state, trait) {
      state.activeTraits.push(trait);
    },
    [types.ASSISTANTWIZARD_REMOVE_ACTIVE_TRAIT](state, traitId) {
      state.activeTraits = state.activeTraits.filter(
        trait => trait.id !== traitId
      );
    },
    [types.ASSISTANTWIZARD_SET_CHANNEL](state, channel) {
      state.channel = channel;
    },
    [types.ASSISTANTWIZARD_UPDATE_CHANNEL_CONFIG_ITEM](
      state,
      { channel, item, value }
    ) {
      const config = JSON.parse(JSON.stringify(state.channelConfig));

      if (!config[channel]) {
        config[channel] = {};
      }
      config[channel][item] = value;

      state.channelConfig = config;

      Vue.set(state, 'channelConfig', config);
    },
    [types.ASSISTANTWIZARD_UPDATE_CHANNEL_CONFIG_ITEMS](
      state,
      { channel, items }
    ) {
      const channelUpdate = { [channel]: { ...items } };

      Vue.set(state, 'channelConfig', {
        ...state.channelConfig,
        ...channelUpdate,
      });
    },
    [types.ASSISTANTWIZARD_SET_ERROR](state, field) {
      state.error = field;
    },
    [types.ASSISTANTWIZARD_CLEAR_ERROR](state) {
      state.error = false;
    },
    [types.ASSISTANTWIZARD_CLEAR](state) {
      // Clear to default values
      state.step = 0;
      state.name = randomName();
      state.task = 'generic';
      state.model = 'gpt-4o';
      state.humanEscalation = null;
      state.language = 'en_us';
      state.avatar = false;
      state.channel = 'web_widget';
      state.activeTraits = [];
      state.channelConfig = {};
    },
  },
  actions: {
    validateState({ commit, state }) {
      try {
        commit(types.ASSISTANTWIZARD_CLEAR_ERROR);
        validate(state);
      } catch (e) {
        commit(types.ASSISTANTWIZARD_SET_ERROR, e.field);
      }
    },
    next({ commit, state }) {
      if (state.step >= state.totalSteps) {
        return;
      }

      try {
        validate(state);
        commit(types.ASSISTANTWIZARD_NEXT_STEP);
      } catch (e) {
        commit(types.ASSISTANTWIZARD_SET_ERROR, e.field);
      }
    },
    previous({ commit, state }) {
      if (state.step <= 0) {
        return;
      }

      try {
        validate(state);
        commit(types.ASSISTANTWIZARD_PREVIOUS_STEP);
      } catch (e) {
        commit(types.ASSISTANTWIZARD_SET_ERROR, e.field);
      }
    },
    updateName({ commit }, name) {
      commit(types.ASSISTANTWIZARD_SET_NAME, name);
    },
    updateTask({ commit }, task) {
      commit(types.ASSISTANTWIZARD_SET_TASK, task);
    },
    updateHumanEscalation({ commit }, humanEscalation) {
      commit(types.ASSISTANTWIZARD_SET_HUMAN_ESCALATION, humanEscalation);
      commit(types.ASSISTANTWIZARD_SET_HUMAN_ESCALATION_CONFIG, {});
    },
    updateHumanEscalationConfig({ commit }, { item, value }) {
      commit(types.ASSISTANTWIZARD_SET_HUMAN_ESCALATION_CONFIG, {
        item: item,
        value: value,
      });
    },
    updateLanguage({ commit }, language) {
      commit(types.ASSISTANTWIZARD_SET_LANGUAGE, language);
    },
    updateAvatar({ commit }, avatar) {
      commit(types.ASSISTANTWIZARD_SET_AVATAR, avatar);
    },
    addTrait({ commit }, trait) {
      commit(types.ASSISTANTWIZARD_ADD_ACTIVE_TRAIT, trait);
    },
    removeTrait({ commit }, traitId) {
      commit(types.ASSISTANTWIZARD_REMOVE_ACTIVE_TRAIT, traitId);
    },
    updateChannel({ commit }, channel) {
      commit(types.ASSISTANTWIZARD_SET_CHANNEL, channel);
    },
    updateChannelConfigItem({ commit }, { channel, item, value }) {
      commit(types.ASSISTANTWIZARD_UPDATE_CHANNEL_CONFIG_ITEM, {
        channel,
        item,
        value,
      });
    },
    updateChannelConfigItems({ commit }, { channel, items }) {
      commit(types.ASSISTANTWIZARD_UPDATE_CHANNEL_CONFIG_ITEMS, {
        channel,
        items,
      });
    },
    clear({ commit }) {
      commit(types.ASSISTANTWIZARD_CLEAR);
    },
    async completeWizard({ dispatch, state, commit }) {
      try {
        validate(state);
        const res = await dispatch(
          'assistants/generate',
          {
            name: state.name,
            task: state.task,
            language: state.language,
            avatar_url: state.avatar,
            model: state.model,
            human_escalation: {
              type: state.humanEscalation,
              config: state.humanEscalationConfig,
            },
            channel_type: state.channel,
            channel_config: state.channelConfig[state.channel],
          },
          { root: true }
        );

        commit(types.ASSISTANTWIZARD_CLEAR);

        return res;
      } catch (e) {
        if (e.name === 'ValidationError') {
          commit(types.ASSISTANTWIZARD_SET_ERROR, e.field);
        }

        throw e;
      }
    },
  },
};
