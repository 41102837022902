import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import AssistantsApi from '../../api/assistants';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getInstructions($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getInstruction: $state => instructionId => {
    const [instruction] = $state.records.filter(
      record => record.id === Number(instructionId)
    );
    return instruction || {};
  },
};

export const actions = {
  fetchInstructions: async ({ commit }, rookooAgentId) => {
    commit(types.SET_INSTRUCTION_UI_FLAG, { isFetching: true });
    try {
      const response = await AssistantsApi.fetchInstructions(rookooAgentId);
      commit(types.SET_INSTRUCTIONS, response.data.items);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_INSTRUCTION_UI_FLAG, { isFetching: false });
    }
  },
  createInstruction: async ({ commit }, { rookooAgentId, instruction }) => {
    commit(types.SET_INSTRUCTION_UI_FLAG, { isCreating: true });
    try {
      const response = await AssistantsApi.createInstruction(rookooAgentId, { instruction });
      commit(types.ADD_INSTRUCTION, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_INSTRUCTION_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  deleteInstruction: async ({ commit }, { rookooAgentId, id }) => {
    commit(types.SET_INSTRUCTION_UI_FLAG, { isDeleting: true });
    try {
      await AssistantsApi.destroyInstruction(rookooAgentId, id);
      commit(types.DELETE_INSTRUCTION, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_INSTRUCTION_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_INSTRUCTION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_INSTRUCTIONS]: MutationHelpers.set,
  [types.ADD_INSTRUCTION]: MutationHelpers.setSingleRecord,
  [types.DELETE_INSTRUCTION]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};